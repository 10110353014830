/**
Themes

For forms on dark backgrounds, use the
<strong>form--light</strong> class on
the <strong>form</strong> element or
other wrapper.

Markup:
<form class="form--light">
  <div class="form__field">
    <label for="sample-input">Label</label>
    <input value="" id="sample-input" aria-label="Sample input" />
    <p>Helper text (optional)</p>
  </div>
  <div class="form__field">
    <input id="sample-input" aria-label="Sample input" value="Disabled" disabled/>
    <p>Helper text (optional)</p>
  </div>
  <div class="form__field">
    <label for="sample-select">Label (optional)</label>
    <select id="sample-select">
      <option value="" selected="selected">Selection</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
    <p>Helper text (optional)</p>
  </div>
  <div class="form__field">
    <select id="sample-select" disabled>
      <option value="" selected="selected">Disabled</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
    <p>Helper text (optional)</p>
  </div>
  <div class="form__field form__field--date">
    <select name="month">
      <option value="">Month</option>
      <option value="01">January</option>
      <option value="02">February</option>
      <option value="03">March</option>
      <option value="04">April</option>
      <option value="05">May</option>
      <option value="06">June</option>
      <option value="07">July</option>
      <option value="08">August</option>
      <option value="09">September</option>
      <option value="10">October</option>
      <option value="11">November</option>
      <option value="12">December</option>
    </select>
    <select name="day">
      <option value="">Day</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
    </select>
    <select name="year">
      <option value="">Year</option>
      <option value="1900">1900</option>
      <option value="1901">1901</option>
      <option value="1902">1902</option>
    </select>
  </div>
</form>

Weight: 3.9

Style guide: Forms - Themes
 */
/* stylelint-disable no-descending-specificity */
.form--light input:not([disabled]),
.form--light select:not([disabled]) {
  background-color: var(--color-leafly-white);
}
/* stylelint-enable no-descending-specificity */
