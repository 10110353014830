/*
Expandable Containers

This component wraps child components in a div container with an interactive button at the bottom.
It is intended to be used with Card type components but can be used with any component.

Markup: expandable-container.html

Weight: 0

Style guide: Components - Expandable Container
*/

.expandable-container {
  --initial-container-height: 188px;

  max-height: var(--initial-container-height);
  overflow-y: hidden;
  transition: max-height ease-in 0.27s;
}

.expandable-container--open {
  --max-container-height: 600px;

  max-height: var(--max-container-height);
}

.expandable-container__no-button {
  max-height: 100%;
}

.expandable-container__button {
  background-image: linear-gradient(
    rgb(255 255 255 / 30%),
    var(--color-white) 40%
  );
  cursor: pointer;
  height: 48px;
  position: relative;
  transition: background-image ease-in-out 0.2s;

  @apply -mt-xl;
  @apply flex;
  @apply font-bold;
  @apply items-end;
  @apply justify-start;
  @apply mb-xl;
  @apply text-xs;
  @apply underline;
  @apply w-full;
  @apply z-10;
}

.expandable-container__button--leafly-white {
  background-image: linear-gradient(
    rgb(246 246 246 / 30%),
    var(--color-leafly-white) 40%
  );
}

.expandable-container__button--open,
.expandable-container__button--open--leafly-white {
  height: 38px;

  @apply mt-none;
}

.expandable-container__button--open {
  background-image: linear-gradient(rgb(255 255 255 / 0%), var(--color-white));
}

.expandable-container__button--open--leafly-white {
  background-image: linear-gradient(
    rgb(246 246 246 / 0%),
    var(--color-leafly-white)
  );
}

.expandable-container__button--hidden {
  display: none;
}

/* potential breakpoint heights: sm - 198px md - 220px lg - 261px */
@screen md {
  .md\:expandable-container__button--hidden {
    display: none;
  }

  .md\:expandable-container__no-button {
    max-height: 100%;
  }
}

@screen lg {
  .lg\:expandable-container__button--hidden {
    display: none;
  }

  .lg\:expandable-container__no-button {
    max-height: 100%;
  }
}
