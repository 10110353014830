/**
Buttons

Weight: 4

Style guide: Buttons
 */

button {
  color: currentcolor;
}

button svg {
  pointer-events: none;
}

/*
Buttons

A standard Botanic button. The default style is intended for secondary actions.

Markup:
<div row>
  <h5 class="pb-sm">Individual</h5>
  <div class="col mx-auto">
    <button class="{{modifier_class}}">Click here</button>
  </div>
</div>
<div row>
  <h5 class="pb-sm">Groups</h5>
  <div class="col-1/2">
    <button class="{{modifier_class}}">Click here</button>
  </div>
  <div class="col-1/2">
    <button class="{{modifier_class}}">Click here</button>
  </div>
</div>

.button                    - Default styling. Buttons expand to full width on mobile and contract to 322px on tablet and up. This is for anything “funnel” related. On any given touchpoint, these will wayfind the user down the funnel and to a purchase
.button.button--secondary   - Secondary buttons will accommodate any other key function on a touchpoint that is not funnel-related. This might be a “write a review” button, or something otherwise non-navigational.
.button.button--full       - A button which breaks the max-width size contraints. On rare occassions, desktop buttons can be 100% of their full *container* width.
.button.button--text                   - Simple text buttons. Text buttons do not expand to full width.

Weight: 1

Style guide: Buttons - Buttons
*/
.button {
  @apply block;
  @apply text-center;
  @apply w-full;
  @apply text-white;
  @apply bg-green;
  @apply font-bold;
  @apply text-[14px];
  @apply px-xl;
  @apply py-sm;

  line-height: 20px;
  border-radius: 32px
}

.button--secondary {
  @apply bg-transparent;
  @apply text-default;

  line-height: 17px;
  border: 1.5px solid #333;
}

@screen md {
  .button {
    max-width: 322px;
  }
}

.button--text {
  background: none;
  width: auto;

  @apply underline;
  @apply text-default;
  @apply p-none;
  @apply text-[14px];
}

.button--full {
  max-width: 100%;

  @apply w-full;
}

/**
Toggle Buttons

Buttons with a selected and deselected state.

Markup:
<div class="text-center p-xxl">
  <button class="{{modifier_class}}">
    <svg class="flex" height="16" width="16">
      <use href="#check" xlink:href="#check" />
    </svg>
    <span class="pl-xs">
      Option
    </span>
  </button>
</div>

.button--toggle-on  - Selected state
.button--toggle-off - Deselected state

Weight: 2

Style guide: Buttons - Toggle Buttons
 */
.button--toggle-on,
.button--toggle-off {
  @apply border-2;
  @apply border-green-60;
  @apply flex;
  @apply font-bold;
  @apply items-center;
  @apply px-sm;
  @apply text-green;
  @apply text-xs;
}

.button--toggle-off > svg {
  @apply hidden;
}

.button--toggle-on {
  @apply bg-green-20;
}

.button--toggle-on > svg {
  @apply block;
}

/**
Button widths

<div class="row mb-xl">
  <div class="col-1/2">
    <button class="button">Half width button</button>
  </div>
  <div class="col-1/2">
    <button class="button">Half width button</button>
  </div>
</div>
<div class="row">
  <div class="col-1/3">
    <button class="button">One-third width button</button>
  </div>
  <div class="col-1/3">
    <button class="button">One-third width button</button>
  </div>
  <div class="col-1/3">
    <button class="button">One-third width button</button>
  </div>
</div>

Weight: 4

Style guide: Buttons - Button widths
 */
