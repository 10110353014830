/**
Style guide: Utilities - Truncate Lines

Markup:
<p>Use .truncate-lines for just one line and .truncate-lines .truncate-2-lines or .truncate-lines .truncate-2-lines for 2 and 3 line truncates respectively.</p>
<div class="row">
    <div class="col-1/3">
        <p class="truncate-lines">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec rutrum congue leo eget malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
    </div>
    <div class="col-1/3">
        <p class="truncate-lines truncate-2-lines">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec rutrum congue leo eget malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
    </div>
    <div class="col-1/3">
        <p class="truncate-lines truncate-3-lines">Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Donec rutrum congue leo eget malesuada. Quisque velit nisi, pretium ut lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus.</p>
    </div>
</div>
 */

.truncate-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.truncate-2-lines {
  -webkit-line-clamp: 2;
}

.truncate-3-lines {
  -webkit-line-clamp: 3;
}
