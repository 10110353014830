/**
Checkboxes

See Markup tab for full implementation and class list.

Markup: checkbox.html

Weight: 3.4

Background: bg-white

Style guide: Forms - Checkboxes
 */
.checkbox__label {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  position: relative;

  @apply text-sm;
}

.checkbox__label--disabled {
  @apply cursor-default;
}

.checkbox__checkmark-container {
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;

  @apply mr-sm;

  position: relative;
  top: 2px;
  width: 20px;
  min-width: 20px;
}

.checkbox__label--before {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkbox__label--before .checkbox__checkmark-container {
  margin-right: 0;

  @apply ml-sm;
}

.checkbox__label--before span:last-child {
  @apply order-first;
}

.checkbox__checkbox-input {
  border: 2px solid;
  border-radius: 2px;
  height: 20px;
  left: 0;
  margin: 0;
  position: absolute;
  transition: all 0.25s ease 0s;
  width: 20px;

  @apply bg-transparent;
}

.checkbox__checkbox-input--disabled,
.checkbox__checkbox-input[disabled] {
  @apply border-grey;
  @apply bg-white;
}

.checkbox__checkmark {
  display: none;
}

.checkbox__checkbox-input:checked + .checkbox__checkmark {
  border-radius: 2px;
  color: var(--color-white);
  display: block;
  z-index: 1;

  @apply bg-default;
}

.checkbox__checkbox-input--disabled:checked + .checkbox__checkmark,
.checkbox__checkbox-input[disabled]:checked + .checkbox__checkmark {
  @apply bg-grey;
}
