/**
Star ratings

Simple star ratings. The default star width is 10px and the default rating is 0%.
Provide a custom `style` attribute to set the rating and optionally use larger/smaller stars,
e.g. `style="--star-width: 16px; --star-percentage: 70%;"` for 3.5 stars. Both settings are optional.

Markup:
<span class="{{modifier_class}}" style="--star-width: 16px; --star-percentage: 70%;"></span>

.star-rating         - Ratings with links
.star-rating.star-rating--dark   - Ratings without links

Weight: 5

Style guide: Components - Star rating
 */
.star-rating {
  --star-width: 10px; /* Default size, can be overridden if larger/smaller ratings are needed */
  --star-percentage: 0%; /* Default width, actual width should be set by element */

  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBkPSJNOC4zNzEwNCA4LjA3M0wxMC41NjMgMy43MjVDMTAuNzkxIDMuMjggMTEuMjQ5IDMgMTEuNzQ5IDNDMTIuMjQ5IDMgMTIuNzA3IDMuMjggMTIuOTM1IDMuNzI1QzEyLjkzNiAzLjcyNiAxMi45MzYgMy43MjggMTIuOTM3IDMuNzI5TDE1LjEzMSA4LjA3NUwxOS4yODkgOC40ODdDMTkuODAzIDguNTMgMjAuMjQ1IDguODY4IDIwLjQyMiA5LjM1M0MyMC41OTggOS44MzggMjAuNDc3IDEwLjM4MSAyMC4xMSAxMC43NDVMMTYuNjUzIDE0LjE3MUwxNy45MzUgMTguODMxQzE4LjA3IDE5LjMzNiAxNy44OTUgMTkuODczIDE3LjQ4OSAyMC4yMDNDMTcuMDg0IDIwLjUzMiAxNi41MjIgMjAuNTkzIDE2LjA1OSAyMC4zNjFMMTEuNzQ5IDE4LjIyNkw3LjQ0OTA0IDIwLjM1NkM2Ljk4MjA0IDIwLjU5IDYuNDIxMDQgMjAuNTI5IDYuMDE1MDQgMjAuMkM1LjYxMDA0IDE5Ljg3IDUuNDM1MDQgMTkuMzMzIDUuNTY5MDQgMTguODI4TDYuODUyMDQgMTQuMTY5TDMuMzkyMDQgMTAuNzQyQzMuMDI1MDQgMTAuMzc5IDIuOTAzMDQgOS44MzUgMy4wODAwNCA5LjM1QzMuMjU2MDQgOC44NjUgMy42OTkwNCA4LjUyNyA0LjIwMTA0IDguNDg2TDguMzcxMDQgOC4wNzNaTTExLjc0OSA0LjcwNEw5LjUyNDA0IDkuMTE2QzkuNDA5MDQgOS4zNDUgOS4xODQwNCA5LjQ5OSA4LjkyOTA0IDkuNTI1TDQuNzE2MDQgOS45NDJMOC4yMTkwNCAxMy40MTJDOC40MTIwNCAxMy42MDIgOC40ODYwNCAxMy44ODIgOC40MTQwNCAxNC4xNDRMNy4xMjAwNCAxOC44NDVMMTEuNDE2IDE2LjcxN0MxMS42MjYgMTYuNjEzIDExLjg3MiAxNi42MTMgMTIuMDgyIDE2LjcxN0wxNi4zODQgMTguODQ4TDE1LjA5IDE0LjE0NkMxNS4wMTggMTMuODg1IDE1LjA5MyAxMy42MDUgMTUuMjg1IDEzLjQxNUwxOC43ODYgOS45NDVMMTQuNTczIDkuNTI3QzE0LjMxOCA5LjUwMiAxNC4wOTMgOS4zNDggMTMuOTc3IDkuMTE5TDExLjc0OSA0LjcwNFoiIGZpbGw9IiMwMTdjNmIiIC8+PC9zdmc+");
  background-size: var(--star-width) auto;
  display: inline-block;
  height: var(--star-width);
  position: relative;
  width: calc(var(--star-width) * 5);
  margin-top: -2px;
}

.star-rating::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4zNzEwNCA4LjA3M0wxMC41NjMgMy43MjVDMTAuNzkxIDMuMjggMTEuMjQ5IDMgMTEuNzQ5IDNDMTIuMjQ5IDMgMTIuNzA3IDMuMjggMTIuOTM1IDMuNzI1TDE1LjEzMSA4LjA3NUwxOS4yODkgOC40ODdDMTkuODAzIDguNTMgMjAuMjQ1IDguODY4IDIwLjQyMiA5LjM1M0MyMC41OTggOS44MzggMjAuNDc3IDEwLjM4MSAyMC4xMSAxMC43NDVMMTYuNjUzIDE0LjE3MUwxNy45MzUgMTguODMxQzE4LjA3IDE5LjMzNiAxNy44OTUgMTkuODczIDE3LjQ4OSAyMC4yMDNDMTcuMDg0IDIwLjUzMiAxNi41MjIgMjAuNTkzIDE2LjA1OSAyMC4zNjFMMTEuNzQ5IDE4LjIyNkw3LjQ0OTA0IDIwLjM1NkM2Ljk4MjA0IDIwLjU5IDYuNDIxMDQgMjAuNTI5IDYuMDE1MDQgMjAuMkM1LjYxMDA0IDE5Ljg3IDUuNDM1MDQgMTkuMzMzIDUuNTY5MDQgMTguODI4TDYuODUyMDQgMTQuMTY5TDMuMzkyMDQgMTAuNzQyQzMuMDI1MDQgMTAuMzc5IDIuOTAzMDQgOS44MzUgMy4wODAwNCA5LjM1QzMuMjU2MDQgOC44NjUgMy42OTkwNCA4LjUyNyA0LjIwMTA0IDguNDg2TDguMzcxMDQgOC4wNzNaIiBmaWxsPSIjMDE3YzZiIi8+PC9zdmc+");
  background-size: var(--star-width) auto;
  content: "";
  height: var(--star-width);
  position: absolute;
  width: var(--star-percentage);
}

.star-rating--dark {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4zNzEwNCA4LjA3M0wxMC41NjMgMy43MjVDMTAuNzkxIDMuMjggMTEuMjQ5IDMgMTEuNzQ5IDNDMTIuMjQ5IDMgMTIuNzA3IDMuMjggMTIuOTM1IDMuNzI1QzEyLjkzNiAzLjcyNiAxMi45MzYgMy43MjggMTIuOTM3IDMuNzI5TDE1LjEzMSA4LjA3NUwxOS4yODkgOC40ODdDMTkuODAzIDguNTMgMjAuMjQ1IDguODY4IDIwLjQyMiA5LjM1M0MyMC41OTggOS44MzggMjAuNDc3IDEwLjM4MSAyMC4xMSAxMC43NDVMMTYuNjUzIDE0LjE3MUwxNy45MzUgMTguODMxQzE4LjA3IDE5LjMzNiAxNy44OTUgMTkuODczIDE3LjQ4OSAyMC4yMDNDMTcuMDg0IDIwLjUzMiAxNi41MjIgMjAuNTkzIDE2LjA1OSAyMC4zNjFMMTEuNzQ5IDE4LjIyNkw3LjQ0OTA0IDIwLjM1NkM2Ljk4MjA0IDIwLjU5IDYuNDIxMDQgMjAuNTI5IDYuMDE1MDQgMjAuMkM1LjYxMDA0IDE5Ljg3IDUuNDM1MDQgMTkuMzMzIDUuNTY5MDQgMTguODI4TDYuODUyMDQgMTQuMTY5TDMuMzkyMDQgMTAuNzQyQzMuMDI1MDQgMTAuMzc5IDIuOTAzMDQgOS44MzUgMy4wODAwNCA5LjM1QzMuMjU2MDQgOC44NjUgMy42OTkwNCA4LjUyNyA0LjIwMTA0IDguNDg2TDguMzcxMDQgOC4wNzNaTTExLjc0OSA0LjcwNEw5LjUyNDA0IDkuMTE2QzkuNDA5MDQgOS4zNDUgOS4xODQwNCA5LjQ5OSA4LjkyOTA0IDkuNTI1TDQuNzE2MDQgOS45NDJMOC4yMTkwNCAxMy40MTJDOC40MTIwNCAxMy42MDIgOC40ODYwNCAxMy44ODIgOC40MTQwNCAxNC4xNDRMNy4xMjAwNCAxOC44NDVMMTEuNDE2IDE2LjcxN0MxMS42MjYgMTYuNjEzIDExLjg3MiAxNi42MTMgMTIuMDgyIDE2LjcxN0wxNi4zODQgMTguODQ4TDE1LjA5IDE0LjE0NkMxNS4wMTggMTMuODg1IDE1LjA5MyAxMy42MDUgMTUuMjg1IDEzLjQxNUwxOC43ODYgOS45NDVMMTQuNTczIDkuNTI3QzE0LjMxOCA5LjUwMiAxNC4wOTMgOS4zNDggMTMuOTc3IDkuMTE5TDExLjc0OSA0LjcwNFoiIGZpbGw9IiMzMzMzMzMiIC8+PC9zdmc+");
}

.star-rating--dark::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4zNzEwNCA4LjA3M0wxMC41NjMgMy43MjVDMTAuNzkxIDMuMjggMTEuMjQ5IDMgMTEuNzQ5IDNDMTIuMjQ5IDMgMTIuNzA3IDMuMjggMTIuOTM1IDMuNzI1TDE1LjEzMSA4LjA3NUwxOS4yODkgOC40ODdDMTkuODAzIDguNTMgMjAuMjQ1IDguODY4IDIwLjQyMiA5LjM1M0MyMC41OTggOS44MzggMjAuNDc3IDEwLjM4MSAyMC4xMSAxMC43NDVMMTYuNjUzIDE0LjE3MUwxNy45MzUgMTguODMxQzE4LjA3IDE5LjMzNiAxNy44OTUgMTkuODczIDE3LjQ4OSAyMC4yMDNDMTcuMDg0IDIwLjUzMiAxNi41MjIgMjAuNTkzIDE2LjA1OSAyMC4zNjFMMTEuNzQ5IDE4LjIyNkw3LjQ0OTA0IDIwLjM1NkM2Ljk4MjA0IDIwLjU5IDYuNDIxMDQgMjAuNTI5IDYuMDE1MDQgMjAuMkM1LjYxMDA0IDE5Ljg3IDUuNDM1MDQgMTkuMzMzIDUuNTY5MDQgMTguODI4TDYuODUyMDQgMTQuMTY5TDMuMzkyMDQgMTAuNzQyQzMuMDI1MDQgMTAuMzc5IDIuOTAzMDQgOS44MzUgMy4wODAwNCA5LjM1QzMuMjU2MDQgOC44NjUgMy42OTkwNCA4LjUyNyA0LjIwMTA0IDguNDg2TDguMzcxMDQgOC4wNzNaIiBmaWxsPSIjMzMzMzMzIi8+PC9zdmc+");
}
