/*
Display utilities

These mobile-first responsive utilities make it easier to show/hide elements at different screen sizes.
Mobile attributes are applied first and attributes for wider screen sizes will take precedence at those screen sizes.
Attributes that make elements visible will generally take precedence over attributes that hide elements.
For example, `<div hide show-md />` would be hidden at mobile sizes and become visible at tablet sizes.

.hidden              - Hides an element regardless of screen width (>=0px)
.md:hidden           - Hides an element starting at tablet screen widths (>=768px)
.lg:hidden           - Hides an element starting at desktop screen widths (>=1025px)
.block               - Displays an element regardless of screen width (>=0px) using `display: block`
.md:block            - Displays an element starting at tablet screen widths (>=768px) using `display: block`
.lg:block            - Displays an element starting at desktop screen widths (>=1025px) using `display: block`
.md:flex             - Displays an element starting at tablet screen widths (>=768px) using `display: flex`
.lg:flex             - Displays an element starting at desktop screen widths (>=1025px) using `display: flex`
.md:inline           - Displays an element starting at tablet screen widths (>=768px) using `display: inline`
.lg:inline           - Displays an element starting at desktop screen widths (>=1025px) using `display: inline`

Markup:
<div class="{{modifier_class}}">
  Hidden or visible text (resize screen to see other attributes in action)
</div>

Weight: 2.6

Style guide: Layout - Utilities
*/

[hide], /* DEPRECATED as of v4.0.0 */
.hidden {
  display: none;
}

[show], /* DEPRECATED as of v4.0.0 */
.block {
  display: block;
}

@screen md {
  [hide-md], /* DEPRECATED as of v4.0.0 */
  .md\:hidden {
    display: none;
  }

  [show-md], /* DEPRECATED as of v4.0.0 */
  [show-md="block"], /* DEPRECATED as of v4.0.0 */
  .md\:block {
    display: block;
  }

  [show-md="inline"], /* DEPRECATED as of v4.0.0 */
  .md\:inline {
    display: inline;
  }

  [show-md="flex"], /* DEPRECATED as of v4.0.0 */
  .md\:flex {
    display: flex;
  }
}

@screen lg {
  [hide-lg], /* DEPRECATED as of v4.0.0 */
  .lg\:hidden {
    display: none;
  }

  [show-lg], /* DEPRECATED as of v4.0.0 */
  [show-lg="block"], /* DEPRECATED as of v4.0.0 */
  .lg\:block {
    display: block;
  }

  [show-lg="inline"], /* DEPRECATED as of v4.0.0 */
  .lg\:inline {
    display: inline;
  }

  [show-lg="flex"], /* DEPRECATED as of v4.0.0 */
  .lg\:flex {
    display: flex;
  }
}
