/**
Image Aspect Ratios

Use `.image-wrapper.image--1:1` for square images.
Use `.image-wrapper.image--5:4` for a 5:4 aspect ratio.
Use `.image-wrapper.image--4:3` for a 4:3 aspect ratio.
Use `.image-wrapper.image--16:9` for a 16:9 aspect ratio.

Markup:
<h4>.image-wrapper.image--1:1</h4>
<div class="image-wrapper image--1:1">
  <img src="https://placekitten.com/g/1350/1050" alt="Kitten" />
</div>
<h4>.image-wrapper.image--5:4</h4>
<div class="image-wrapper image--5:4">
  <img src="https://placekitten.com/g/1350/1050" alt="Kitten" />
</div>
<h4>.image-wrapper.image--4:3</h4>
<div class="image-wrapper image--4:3">
  <img src="https://placekitten.com/g/1350/1050" alt="Kitten" />
</div>
<h4>.image-wrapper.image--16:9</h4>
<div class="image-wrapper image--16:9">
  <img src="https://placekitten.com/g/1350/1050" alt="Kitten" />
</div>

Style guide: Utilities - Image ratios
 */
.image-wrapper {
  position: relative;
  width: 100%;
}

.image--1\:1 {
  padding-bottom: 100%;
}

.image--5\:4 {
  padding-bottom: 80%;
}

.image--4\:3 {
  padding-bottom: 75%;
}

.image--16\:9 {
  padding-bottom: 56.25%;
}

.card__image > img,
.image-wrapper > img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}

/**
LQIP Blur

We use [lazysizes](https://github.com/aFarkas/lazysizes) to make image lazy loading easy.
If you're using Low Quality Image Placeholders (LQIP), you may want to blur them until your actual images load.
Our core styles include lazysizes' recommended method for blurring those placeholders
(https://github.com/aFarkas/lazysizes#lqipblurry-image-placeholderblur-up-image-technique).

.blur.lazyload - Blurs low quality placeholder image until actual image loads.
                 Remember to add a `data-src` or `data-srcset` attribute so `lazysizes` knows which high quality image to lazy load.

Markup:
<img src="https://placekitten.com/g/75/75" data-src="https://placekitten.com/g/2500/2500" class="{{modifier_class}} w-full" alt="Kitten" />


Style guide: Utilities - LQIP Blur
 */
.blur {
  filter: blur(8px);
  transition: filter 600ms;
}

.blur.lazyloaded {
  filter: blur(0);
}
