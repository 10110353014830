/**
Map Marker

A map marker for use inside of Google Maps

Markup: map-marker.html

Weight: 11

Style guide: Components - Map Marker
 */

.map-marker {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  @apply rounded-full;
}

.map-marker > img {
  height: 100%;
  object-fit: contain;
  width: 100%;

  @apply rounded-full;
}

.map-marker > svg {
  max-height: 70%;
  max-width: 70%;

  @apply text-white;
}

.map-marker--sm {
  height: 12px;
  left: -6px;
  top: -6px;
  width: 12px;

  @apply bg-deep-green-60;
}

.map-marker--md {
  border-bottom-left-radius: 0;
  height: 22px;
  left: -11px;
  top: -26.2px;
  transform: rotate(-45deg);
  width: 22px;

  @apply bg-green;
}

.map-marker--lg {
  height: 50px;
  left: -25px;
  padding: 4px;
  top: -25px;
  width: 50px;

  @apply bg-green;
}

.map-marker--xl {
  border-bottom-left-radius: 0;
  border: solid 3px var(--color-white);
  height: 86px;
  left: -43px;
  padding: 4px;
  top: -102px;
  transform: rotate(-45deg);
  width: 86px;

  @apply bg-yellow;
}

.map-marker--m > .map-marker__image,
.map-marker--xl > .map-marker__image {
  transform: rotate(45deg);
}
