h1,
h2,
h3,
h4,
h5,
h6,
[class^="heading--"],
[class*=" heading--"] {
  @apply font-extrabold;
}

/*
Headings

Markup:
<h1 class="heading--hero">Heading H1 Hero</h1>
<h1>Heading H1</h1>
<h2>Heading H2</h2>
<h3>Heading H3</h3>
<h4>Heading H4</h4>
<h5>Heading H5</h5>

Weight: 200

Style guide: Typography - Headings
*/
.heading--hero {
  @apply text-hero;
}

h1,
.heading--xl {
  @apply text-xl;
}

h2,
.heading--l {
  @apply text-lg;
}

h3,
.heading--m {
  @apply text-md;
}

h4,
.heading--s {
  @apply text-sm;
}

h5,
h6,
.heading--xs {
  @apply text-xs;
}

/**
Headings, light variant

.heading--hero.text-white  - h0 heading. Used for hero copy.
.heading--xl.text-white    - h1 heading. Used for h1 tags.
.heading--l.text-white     - h2 heading. Used for h2 tags.
.heading--m.text-white     - h3 heading. Used for h3 tags.
.heading--s.text-white     - h5 heading. Used for h4 tags.
.heading--xs.text-white     - h5 heading. Used for h5 tags.
.heading--xs.text-white     - h6 heading. Used for h6 tags.

Markup:
<div class="bg-default p-xxl">
  <button class="{{modifier_class}}">Heading</button>
</div>

Weight: 210

Style guide: Typography - Heading variations
 */
