/**
Full bleed utility

Weight: 4.2

Markup:
Use <strong>.full-bleed</strong> to break of out of the `.container` to the edge of the window

<div class="overflow-hidden">
  <div class="full-bleed">
    <img src="https://leafly-public-integration.imgix.net/web-home/homepage-hero.png">
  </div>
</div>

Style guide: Utilities - Full Bleed
 */

.full-bleed {
  margin: 0 var(--grid-margin-width);
}

@screen md {
  .full-bleed {
    margin: 0 calc(-1 * calc(100vw - var(--grid-width)) / 2);
  }
}
