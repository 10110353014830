/**
Card

Sets a default width and background color for card components.
Cards may appear in grids, carousels, or occasionally on their own.

.card             - Sets default width and background color
.card.card--h-lg  - Also sets a fixed height for cards (368px)

Markup:
<div class="card p-sm {{modifier_class}}">
   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
   Donec maximus consectetur nibh a tempor.
   Vivamus eu mauris metus. Curabitur at justo dui.
   Integer condimentum libero nisi, a scelerisque enim tristique a.
</div>

Weight: 1

Style guide: Patterns - Cards
 */
.card {
  position: relative;
  width: 225px;

  @apply bg-white;
  @apply mr-md;
}

.card:last-of-type {
  @apply mr-none;
}

@screen md {
  .card {
    width: 299px;
  }
}

@screen lg {
  .card {
    width: 239px;
  }
}

.card--h-lg {
  height: 368px;
}

/**
Card Headers

Some types of text-heavy cards have headers (e.g. review cards or update cards).

Markup:
<!-- Card title -->
<div class="card__header">
  <img src="https://via.placeholder.com/150" alt="Logo" class="card__thumbnail" />
  <div class="card__title">
    <h4 class="heading--m">Card title</h4>
    <div class="text-xs">11/24/2011</div>
  </div>
</div>
<!-- Card title, no image -->
<div class="card__header">
  <div class="card__title">
    <h4 class="heading--m">Card title, no image</h4>
    <div class="text-xs">11/24/2011</div>
  </div>
</div>
<!-- Card title, large logo -->
<div class="card__header">
  <img src="https://via.placeholder.com/150" alt="Logo" class="card__thumbnail card__thumbnail--large" />
  <div class="card__title">
    <h4 class="heading--m">Card title, large image</h4>
    <div class="text-xs">11/24/2011</div>
  </div>
</div>

Weight: 2

Style guide: Patterns - Card Headers
 */
.card__header {
  @apply border-b;
  @apply border-deep-green-40;
  @apply mb-lg;
  @apply pb-sm;

  align-items: center;
  display: flex;
}

.card__thumbnail {
  @apply mr-md;
  @apply rounded-full;

  height: 55px;
  width: 55px;
}

.card__thumbnail--large {
  height: 92px;
  width: 92px;
}

.card__title {
  @apply pr-md;
  @apply pt-xs;
}

.card__ad-flag {
  font-size: 12px;
  position: absolute;
  right: 0;
  top: -20px;
}

/**
Card Images

Some types of cards display an image above their content instead of a header.

Markup:
<div class="card">
  <div class="card__image">
    <img src="https://placekitten.com/g/300/300" alt="Kitten" />
  </div>
  <div class="p-sm">
   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
   Donec maximus consectetur nibh a tempor.
   Vivamus eu mauris metus. Curabitur at justo dui.
   Integer condimentum libero nisi, a scelerisque enim tristique a.
  </div>
</div>

Weight: 3

Style guide: Patterns - Card Images
 */
.card__image {
  height: 160px;

  @apply image-wrapper;
}
