/**
Dropdown Menu

Markup: dropdown.html

Weight: 10

Style guide: Components - Dropdown
 */
.dropdown__container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;

  @apply font-bold;
}

.dropdown__icon {
  transition: transform 150ms ease-in-out;
}

.dropdown__menu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  min-width: 100%;
  z-index: 51;

  @apply shadow-low;
  @apply py-sm;
  @apply bg-white;
}

.dropdown--open > .dropdown__icon {
  transform: rotate(180deg);
}

.dropdown--open > .dropdown__menu {
  display: block;
}

.dropdown__item {
  display: block;
  line-height: 1.6;
  text-align: left;
  width: 100%;

  @apply relative;
  @apply px-md;
  @apply py-xs;
  @apply text-default;
}

.dropdown__item:hover {
  @apply text-green;
}

@screen lg {
  .list-element {
    @apply my-sm;
  }
}
