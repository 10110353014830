/**
Featured Dispensary Card

Featured dispensary cards are advertisements for dispensaries. They're shown as a set between 1 to 3 at a time.

Markup: featured-dispensary-card.html

Weight: 8

Style guide: Patterns - Featured Dispensary Card
 */
.featured-dispensary__container {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply mt-xl;
}

.featured-dispensary__card {
  @apply bg-white;
  @apply border-none;
  @apply flex;
  @apply mb-md;
  @apply mt-sm;
  @apply p-sm;
  @apply relative;
  @apply w-full;
}

@screen md {
  .featured-dispensary__container {
    @apply flex-row;
  }

  .featured-dispensary__card {
    @apply flex-1;
    @apply max-w-half;
    @apply mr-md;
  }

  .featured-dispensary__card:last-child {
    @apply mr-none;
  }
}
