/**
Update Card

Markup:
<h4>Default</h4>
<a href="#" class="update-card">
  <div class="card__header">
    <img src="https://via.placeholder.com/150" alt="Logo" class="card__thumbnail" />
    <div class="card__title">
      <h4 class="heading--m">Dispensary name</h4>
      <div class="text-xs">11/24/2011</div>
    </div>
  </div>
  Free cannabis all day!
</a>

Weight: 18

Style guide: Patterns - Update Card
*/
.update-card {
  @apply bg-white;
  @apply p-md;
  @apply text-left;
  @apply text-sm;

  display: block;
  width: 100%;
}
