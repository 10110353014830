/**
Image 

Image container and element with multiple ways to display and image. Must be used as frontend component.  

.image-container                          - Default image style

Markup:
<div class="image-container block relative overflow-hidden">
    <img 
        data-srcset="https://leafly-public.imgix.net/web-home/product-categories/cannabis.jpg?auto=compress,format&amp;w=240&amp;dpr=1 1x, https://leafly-public.imgix.net/web-home/product-categories/cannabis.jpg?auto=compress,format&amp;w=240&amp;dpr=2 2x" 
        data-testid="image-single-image" 
        alt="Cannabis product image" 
        class="max-w-full h-full w-full origin-center absolute lazyload" 
    >
</div>

Style guide: Components - Image
 */
.image-container .error {
  opacity: 0;
  display: flex;
  transition: opacity 0.2s ease;
}

.image-container.apply-ratio img {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container.image-error img,
.image-container img:not(.no-lazyload) {
  opacity: 0;
  transition: opacity 0.2s;
}

.image-container.image-error .error,
.image-container img.lazyloaded {
  opacity: 1;
}
