/** ************************************************************* **/

/**                                                               **/

/**         Leafly-specific Tailwind class documentation          **/

/**                                                               **/

/** ************************************************************* **/

/**

Our custom Tailwind config overrides and eliminates some of the default styles Tailwind provides.
We'll provide documentation for these changes in our style guide instead.

 */

/**
Utilities

Weight: 100

Style guide: Utilities
*/
@import "./icons.css";
@import "./images.css";
@import "./full-bleed";
@import "./truncate";

@layer utilities {
  .fade-white-right {
    @apply after:content-[''];
    @apply after:w-[55px];
    @apply after:absolute;
    @apply after:bottom-0;
    @apply after:right-0;
    @apply after:top-0;
    @apply after:bg-gradient-to-l;
    @apply after:from-white;
    @apply after:from-10%;
    @apply after:to-transparent;
  }
  
  .fade-leafly-white-right {
    @apply after:content-[''];
    @apply after:w-[55px];
    @apply after:absolute;
    @apply after:bottom-0;
    @apply after:right-0;
    @apply after:top-0;
    @apply after:bg-gradient-to-l;
    @apply after:from-leafly-white;
    @apply after:from-10%;
    @apply after:to-transparent;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .dot-separator > *::after {
    content: "•";
    margin: 0 8px;
  }

  .dot-separator > *:last-child::after {
    content: "";
    margin: 0;
  }
}
