/**
Order items

If you need a column to appear in a different order than it would by default, use an ordering utility attribute.

These attributes apply `order: <NUMBER>` properties to flex or grid items, where `<NUMBER>` is >= 1 and <= 12.
 <br />
To apply styles starting at tablet sizes, prefix with <strong>md:</strong>, e.g. <strong>md:order-1</strong>. <br />
To apply styles starting at desktop sizes, prefix with <strong>lg:</strong>, e.g. <strong>lg:order-1</strong>.

Markup: ordering.html

Weight: 2.9

Style guide: Layout - Utilities - Order
 */

/**
[DEPRECATED] Ordering Utilities

Use Tailwind-based styles (see previous sections).

Markup: <div class="kss-section">
  <h3>Default, mobile-first</h3>
  <div row>
    <div col="1" col-order="last">
      <div class="sample-col text-sm">col-order="last"</div>
    </div>
    <div col="1" col-order="first">
      <div class="sample-col text-sm">col-order="first"</div>
    </div>
    <div col="1" col-order="default">
      <div class="sample-col text-sm">col-order="default"</div>
    </div>
    <div col="1" col-order="2">
      <div class="sample-col text-sm">col-order="2"</div>
    </div>
    <div col="1" col-order="5">
      <div class="sample-col text-sm">col-order="5"</div>
    </div>
    <div col="1" col-order="11">
      <div class="sample-col text-sm">col-order="11"</div>
    </div>
  </div>
</div>

Weight: 2.10

Style guide: DEPRECATED - Ordering
 */
[col-order="default"] {
  order: 0;
}

[col-order="first"] {
  order: -1;
}

[col-order="1"] {
  order: 1;
}

[col-order="2"] {
  order: 2;
}

[col-order="3"] {
  order: 3;
}

[col-order="4"] {
  order: 4;
}

[col-order="5"] {
  order: 5;
}

[col-order="6"] {
  order: 6;
}

[col-order="7"] {
  order: 7;
}

[col-order="8"] {
  order: 8;
}

[col-order="9"] {
  order: 9;
}

[col-order="10"] {
  order: 10;
}

[col-order="11"] {
  order: 11;
}

[col-order="last"],
[col-order="12"] {
  order: 12;
}

@screen md {
  [col-order-md="default"] {
    order: 0;
  }

  [col-order-md="first"] {
    order: -1;
  }

  [col-order-md="1"] {
    order: 1;
  }

  [col-order-md="2"] {
    order: 2;
  }

  [col-order-md="3"] {
    order: 3;
  }

  [col-order-md="4"] {
    order: 4;
  }

  [col-order-md="5"] {
    order: 5;
  }

  [col-order-md="6"] {
    order: 6;
  }

  [col-order-md="7"] {
    order: 7;
  }

  [col-order-md="8"] {
    order: 8;
  }

  [col-order-md="9"] {
    order: 9;
  }

  [col-order-md="10"] {
    order: 10;
  }

  [col-order-md="11"] {
    order: 11;
  }

  [col-order-md="last"],
  [col-order-md="12"] {
    order: 12;
  }
}

@screen lg {
  [col-order-lg="default"] {
    order: 0;
  }

  [col-order-lg="first"] {
    order: -1;
  }

  [col-order-lg="1"] {
    order: 1;
  }

  [col-order-lg="2"] {
    order: 2;
  }

  [col-order-lg="3"] {
    order: 3;
  }

  [col-order-lg="4"] {
    order: 4;
  }

  [col-order-lg="5"] {
    order: 5;
  }

  [col-order-lg="6"] {
    order: 6;
  }

  [col-order-lg="7"] {
    order: 7;
  }

  [col-order-lg="8"] {
    order: 8;
  }

  [col-order-lg="9"] {
    order: 9;
  }

  [col-order-lg="10"] {
    order: 10;
  }

  [col-order-lg="11"] {
    order: 11;
  }

  [col-order-lg="last"],
  [col-order-lg="12"] {
    order: 12;
  }
}
