/*
Forms

Weight: 3

Style guide: Forms
 */

input,
select {
  @apply bg-leafly-white;
  @apply w-full;
  @apply rounded;
  @apply border-light-grey;
}

input[disabled],
select[disabled] {
  @apply bg-light-grey;
}

/*
We were relying on normalize.css to provide this 0 padding rule for box sizing
which we were lost in the upgrade to tailwind v2. This provides us backwards
compatibility for now.
*/
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input {
  @apply px-lg;
  @apply py-md;
  @apply rounded;
  @apply border border-light-grey;
  @apply text-base;
}

input#email-input {
  @apply border-0 border-r;
}

.text-error input {
  outline: 2px solid var(--color-error);
  outline-offset: -2px;
}

input::placeholder,
textarea::placeholder {
  @apply text-sm;
  @apply text-grey;
}

/**
Fields

Use the <strong>form__field</strong> class to wrap an input field, its label, and any helper text:

Markup: input.html

Weight: 3.0

Style guide: Forms - Form fields
 */
.form__field {
  @apply relative;
  @apply text-md;
  @apply w-full;
}

.form__field > input,
.form__field > p {
  @apply m-none;
  @apply text-default;
}

input:focus-visible {
  outline: none;
  outline: 2px solid var(--color-default);
  outline-offset: -2px;
}

.form__field > p {
  @apply pt-xs;
  @apply text-secondary;
  @apply text-xs;
}

.form__field > label {
  @apply text-xs;
  @apply font-bold;
}
