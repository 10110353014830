/**
Terpene Bar Graph

Provides a (not-to-scale) representation of the top 3 terpenes for a strain.


Markup:
Default:
<div>
  <h5 class="terpene-bar-graph__header">
    Terpenes
  </h5>
  <ol class="terpene-bar-graph">
    <li class="terpene-bar-graph__item">
        <div class="bg-pinene terpene-bar-graph__bar"></div>
        <div class="terpene-bar-graph__label">Pinene</div>
    </li>
    <li class="terpene-bar-graph__item">
        <div class="bg-myrcene terpene-bar-graph__bar"></div>
        <div class="terpene-bar-graph__label">Myrcene</div>
    </li>
    <li class="terpene-bar-graph__item">
        <div class="bg-linalool terpene-bar-graph__bar"></div>
        <div class="terpene-bar-graph__label">Linalool</div>
    </li>
  </ol>
</div>

Weight: 5

Style guide: Patterns - Terpene Bar Graph
 */

.terpene-bar-graph__header {
  @apply font-bold;
  @apply text-xs;
}

.terpene-bar-graph {
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;

  @apply flex;
  @apply justify-between;
}

.terpene-bar-graph__item:nth-of-type(1) {
  width: calc(49.8% - 4px);
}

.terpene-bar-graph__item:nth-of-type(2) {
  width: calc(33.2% - 4px);
}

.terpene-bar-graph__item:nth-of-type(3) {
  width: calc(16.6% - 4px);
}

.terpene-bar-graph__bar {
  height: 8px;

  @apply rounded-sm;
}

.terpene-bar-graph__label {
  @apply text-xs;
  @apply truncate;
}
