/*
Containers

The main container for page content.
Adding a `container` attribute to an element will set a max width and margins.

Markup:
<div class="container">
  Main content
</div>

Weight: 2.0

Style guide: Layout - Basics
 */
[btc-container], /* DEPRECATED */
[container], /* DEPRECATED */
.container {
  @apply mx-lg;
}

@screen md {
  [btc-container], /* DEPRECATED */
  [container], /* DEPRECATED */
  .container {
    max-width: var(--grid-width);
    margin: 0 auto;
    width: 100%;
  }
}

@screen lg {
  [btc-container], /* DEPRECATED */
  [container], /* DEPRECATED */
  .container {
    max-width: var(--grid-width);
    width: 100%;
  }
}

/*
Grid Rows

A logical row, which groups a set of elements that each span N columns.

Markup:
<div class="container">
  <div class="row">
    I'm a row.
  </div>
  <div class="row">
    I'm a row, too!
  </div>
</div>

Weight: 2.1

Style guide: Layout - Grid Rows
*/
[row], /* DEPRECATED */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--grid-gutter-width) / -2);
  margin-right: calc(var(--grid-gutter-width) / -2);
}

/* By default, treat all direct children of a row as full-width elements. This prevents
the markup from requiring useless [col] attributes. */
[row] > *, /* DEPRECATED */
.row > * {
  flex-basis: 100%;
}
