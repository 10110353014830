/**
Carousel

A carousel capable of displaying a variety of cards.

Markup: carousel.html

Weight: 10

Style guide: Components - Carousel
 */
.carousel__mask {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @apply -mx-lg;
}

.carousel__items {
  display: flex;
  transition: transform 500ms ease-in-out;
}

.carousel__items::after {
  color: transparent;
  content: ".";
  display: block;
  font-size: 0.01em;

  @apply ml-lg;
}

.carousel__items > * {
  flex-shrink: 0;
}

.carousel__items > *:first-child {
  @apply ml-lg;
}

.carousel__items > *:last-child {
  @apply mr-none;
}

.carousel__nav {
  display: none;

  @apply mt-md;
}

@screen md {
  .carousel__mask {
    @apply m-none;
  }

  .carousel__items > *:first-child {
    @apply ml-none;
  }
}

@screen lg {
  .carousel__mask {
    overflow: hidden;
  }

  .carousel__nav {
    display: flex;
    justify-content: flex-end;
  }

  .carousel__nav-button:first-of-type {
    @apply mr-lg;
  }

  .carousel__nav--disabled {
    color: var(--color-tertiary);
  }

  .carousel__transform {
    transform: translateX(calc(-100% - 12px));
  }
}
