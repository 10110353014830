/** Please update the corresponding documentation in botanic/utilities/colors.css */
:root {
  --color-default: #333;
  --color-primary: #034638;
  --color-secondary: #707070;
  --color-tertiary: #b3c8c3;
  --color-green: #017c6b;
  --color-deep-green: #034638;
  --color-yellow: #ffcd00;
  --color-white: #fff;
  --color-leafly-white: #f6f6f6;
  --color-caryophyllene: #aa0061;
  --color-notification: #dd007e;
  --color-humulene: #97d700;
  --color-limonene: #eedc00;
  --color-linalool: #5c068c;
  --color-myrcene: #06038d;
  --color-ocimene: #e0004d;
  --color-pinene: #00af66;
  --color-terpinolene: #ff671f;
  --color-grey: #707070;
  --color-light-grey: #e7e7e7;
  --color-error: #dc0045;
  --color-pickup: #ed5d3a;
  --color-verified: #02A5DF;
  --font-size-hero: 40px;
  --font-size-xxl: 32px;
  --font-size-xl: 28px;
  --font-size-l: 24px;
  --font-size-m: 20px;
  --font-size-s: 16px;
  --font-size-xs: 12px;
  --grid-column-width: calc(100% / 6);
  --grid-margin-width: 16px;
  --grid-gutter-width: 12px;
  --grid-width: 736px;
  --z-index-modal: 50;
}

@screen md {
  :root {
    --grid-column-width: calc(100% / 12);
    --grid-width: 736px;
  }
}

@screen lg {
  :root {
    --grid-width: 992px;
  }
}
