/**
Search

Use the <strong>form__field--search</strong> class to automatically add a search icon to search fields:

Markup:
<div class="bg-white p-xl">
  <div class="form__field form__field--search">
    <label for="search-input">Search</label>
    <input id="search-input" type="search" placeholder="Search" aria-label="Search" />
  </div>
</div>

Weight: 3.1
Style guide: Forms - Search
*/
.form__field--search input {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBmaWxsPSIjMDM0NjM4IiBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48L3N2Zz4=");
  background-position: calc(100% - 8px) center;
  background-repeat: no-repeat;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.form__field--clear-search {
  position: absolute;
  top: 18px;
  right: 40px;
}
