@import "./chat.css";
@import "./core.css";

.full-bleed-container {
  margin: 0 calc(-1 * (var(--grid-margin-width)));
}

@media only screen and (min-width: 768px) {
  .full-bleed-container {
    margin: 0 calc(-1 * (100vw - var(--grid-width)) / 2);
  }
}

.gam-rectangular-min-height {
  min-height: 50px;
}

@media only screen and (min-width: 992px) {
  .gam-rectangular-min-height {
    min-height: 90px;
  }
}

.gam-square-fixed-height {
  height: 250px;
}

.gam-rectangular-fixed-height {
  height: 50px;
}

@media only screen and (min-width: 992px) {
  .gam-rectangular-fixed-height {
    height: 90px;
  }
}

.gam-rect-square-fixed-height {
  height: 250px;
}

@media only screen and (min-width: 992px) {
  .gam-rect-square-fixed-height {
    height: 90px;
  }
}

.qselector-spinner-hide::-webkit-inner-spin-button,
.qselector-spinner-hide::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

html {
  scroll-padding-top: var(--header-height);
  scroll-behavior: smooth;
}

/* used to style content within components that use the dangerouselySetInnerHtml prop */
.dangerously-set-html a {
  color: var(--color-green);
}

.dangerously-set-html.text-white a {
  color: var(--color-white);
}

.dangerously-set-html ul,
.dangerously-set-html ol {
  margin: 0.5rem 0;
  padding-left: 20px;
}

.dangerously-set-html ul {
  list-style: disc;
}

.dangerously-set-html ol {
  list-style: decimal;
}
