/**
Typography

Weight: 1

Style guide: Typography
 */

* {
  line-height: calc(1em + 8px);
  text-underline-offset: 0.2em;
}

html,
body {
  color: var(--color-default);
  font-size: var(--font-size-s);

  @apply font-normal;
}

a {
  color: currentcolor;
}

b,
strong {
  @apply font-bold;
}

i,
em {
  font-style: italic;
}

s,
strike {
  text-decoration: line-through;
}

p > a {
  text-decoration: underline;
}

/*
Paragraphs

The default style for all copy. Adjust weight from here to reflect the relative importance of the content.

.text       - Sets a proper line height for all text sizes on non-paragraph elements (16px).
.text-md    - Used for short pieces of text that require more attention than the base style affords (20px).
.text-sm    - Used for less important or supporting copy (16px).
.text-xs    - Used for unimportant text or copy that can be safely ignored much of the time (12px).

Markup:
<p class="{{modifier_class}}">
  It was a quarter past six when we left Baker Street,
  and it still wanted ten minutes to the hour when
  we found ourselves in Serpentine Avenue.
  It was already dusk, and the lamps were just being
  lighted as we paced up and down in front of Briony Lodge.
</p>

Colors:
.text-deep-green: #034638     - Default text color
.text-green: #017c6b          - Leafly green, used for emphasis
.text-deep-green-alt: #b3c8c3 - 40% of default green, used for de-emphasis.
.text-white: #ffffff          - White text, used on dark backgrounds
.text-error: #dc0045          - Red text, used for error messages

Weight: 1.2

Style guide: Typography - Text
*/
p {
  font-size: var(--font-size-s);
  margin-bottom: 0.5em;
}

.text {
  line-height: calc(1em + 8px);
}

/**
Utilities

Alignment utilities available via Tailwind classes.

.text-center  - Centered text
.text-left    - Left-aligned text
.text-right   - Right-aligned text
.text-justify - Justified text

Markup:
<p class="{{modifier_class}}">
  "Then, good-night, your Majesty, and I trust that we shall soon have some
  good news for you. And good-night, Watson," he added,
  as the wheels of the royal brougham rolled down the street.
</p>

Weight: 1.3

Style guide: Typography - Utilities
 */
