.chat--expanded {
  width: 320px;
}

@media screen and (max-width: 450px) {
  .chat--expanded {
    width: 100vw;
    height: 100vh;
    min-height: stretch;
    max-width: 100%;
    margin: 0;
    bottom: 0;
    right: 0;
  }
}

.chat--collapsed {
  display: flex;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  cursor: pointer;

  /* accordion is 120 */

  /* add to bag modal is 100 */
}

@media screen and (max-width: 450px) {
  .chat--collapsed {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    bottom: 0;
    right: 0;
  }
}

.chat--count {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  cursor: pointer;

  /* accordion is 120 */

  /* add to bag modal is 100 */
}

@media screen and (max-width: 450px) {
  .chat--count {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    bottom: 0;
    right: 0;
  }
}

.chat {
  /* display: flex; */
  align-self: flex-end;
  flex-flow: row-reverse wrap-reverse;
  align-items: flex-end;
  flex-basis: auto;
  max-height: 100%;

  /* need to raise this due to homepage carousels having a z-index */
  z-index: 120;
}

@media screen and (max-width: 450px) {
  /* for the checkout modal and accordion */
  .chat[data-expanded="true"] {
    z-index: 121;
  }

  .chat[data-expanded="false"] {
    z-index: 99;
  }
}

.chat--card {
  margin-bottom: 0;
  margin-top: 1px;
}

.chat--header {
  flex-direction: column;
}

.chat--container {
  display: flex;
  flex-direction: row;
}

.chat--button {
  border-radius: 50%;
  padding: 12px;
}

.chat--messages-list {
  height: 350px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.chat--message {
  word-break: break-word;
  clear: both;
}

.chat--message-container {
  flex-shrink: 0;
  flex-basis: auto;
}

.chat--border {
  border-bottom: 1px solid #e7e7e7;
}

.button-badge {
  border-radius: 50%;
  font-size: 12px;
  position: absolute;
  text-align: center;
  width: 20px;
  height: 20px;
  top: -2px;
  right: -2px;
}

.button-badge-multi {
  top: -5px;
  right: -5px;
}

.chat--input {
  border-top: 1px solid #e7e7e7;
  display: flex;
  gap: 4px;
}

.chat--input > textarea {
  height: 40px;
  display: flex;
  width: 100%;
  resize: none;
}

.chat--input > button {
  height: 40px;
  width: 40px;
  padding: 8px;
  display: flex;
}

.chat--input > textarea::placeholder {
  color: #707070;
}

.chat-spin-left {
  display: inline-block;
  padding: 15px 20px;
  font-size: 14px;
  color: #ccc;
  border-radius: 30px;
  line-height: 1.25em;
  font-weight: 100;
  opacity: 0.2;
}

.chat-spinner {
  margin: 0;
  width: 30px;
  text-align: center;
}

.chat-spinner > div {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  background: rgb(0 0 0 / 100%);
}

.chat-spinner .bounce1 {
  animation-delay: -0.32s;
}

.chat-spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}
