/**
Icon utilities

Weight: 4.2

Markup:
<div>
  Use <strong>.icon--centered</strong> to vertically center icons in containers:
  <button class="button--text icon--centered">
    Helpful (2)
    <svg width="12" height="12" class="ml-xs">
      <use xlinkHref="#thumb_up" href="#thumb_up" />
    </svg>
  </button>
</div>

Style guide: Utilities - Icons
 */
.icon--centered {
  align-items: center;
  display: flex;
}
