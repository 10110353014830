/*
Grid Columns

An element that divs N columns.
The `.col` class sets width of the element in the default (smallest) viewport.
The `.md:col` and `.lg:col` classes each override the preceding
one for progressively larger viewports.

The grid has six (6) columns at mobile sizes and expands to twelve (12) starting at tablet sizes.
All numbered mobile columns will double in size by default starting at tablet sizes
(e.g. `.col-1` in mobile is the equivalent of `col-2` at >=768px).
This behavior can be overridden by using `md:col-{size}` and
`md:col-{size}` to set specific column widths for tablet and desktop sizes
(e.g. `md:col-1` will only be one column wide at tablet and desktop sizes).
You can also specify fractions of the full grid using classes like `.col-1/2` or `.col-1/3`.

Markup: columns.html

Weight: 2.2

Style guide: Layout - Grid Columns
*/
.col,
.md\:col,
.lg\:col,
.col-auto,
.col-0,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-12,
.col-1\/6,
.col-1\/3,
.col-1\/2,
.col-2\/3,
.col-5\/6,
.col-full,
.md\:col-auto,
.md\:col-0,
.md\:col-1,
.md\:col-2,
.md\:col-3,
.md\:col-4,
.md\:col-5,
.md\:col-6,
.md\:col-7,
.md\:col-8,
.md\:col-9,
.md\:col-12,
.md\:col-1\/6,
.md\:col-1\/3,
.md\:col-1\/2,
.md\:col-2\/3,
.md\:col-5\/6,
.md\:col-full,
.lg\:col-auto,
.lg\:col-0,
.lg\:col-1,
.lg\:col-2,
.lg\:col-3,
.lg\:col-4,
.lg\:col-5,
.lg\:col-6,
.lg\:col-7,
.lg\:col-8,
.lg\:col-9,
.lg\:col-12,
.lg\:col-1\/6,
.lg\:col-1\/3,
.lg\:col-1\/2,
.lg\:col-2\/3,
.lg\:col-5\/6,
.lg\:col-full,
[col],  /* DEPRECATED AS OF 3.2.0 */
[col-md], /* DEPRECATED AS OF 3.2.0 -- still used in web-profile and web-dispensary */
[col-lg] /* DEPRECATED AS OF 3.2.0 -- still used in web-strain-explorer and web-dispensary */ {
  flex-grow: 1;
  flex-shrink: 1;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-basis: calc(
    6 * var(--grid-column-width)
  ); /* Default to full, 6-column width */

  max-width: calc(
    6 * var(--grid-column-width)
  ); /* Default to full, 6-column width */

  padding-left: calc(var(--grid-gutter-width) / 2);
  padding-right: calc(var(--grid-gutter-width) / 2);
}

@screen md {
  .col,
  .md\:col,
  .lg\:col,
  [col], /* DEPRECATED AS OF 3.2.0 */
  [col-md], /* DEPRECATED AS OF 3.2.0 */
  [col-lg] /* DEPRECATED AS OF 3.2.0 */ {
    flex-basis: calc(
      12 * var(--grid-column-width)
    ); /* Default to full, 12-column width on tablet */

    max-width: calc(
      12 * var(--grid-column-width)
    ); /* Default to full, 12-column width on tablet */
  }
}

.col-auto,
[col="auto"] /* DEPRECATED AS OF 3.2.0 -- still used in web-strain-explorer */ {
  display: block;
  flex-basis: 0;
  max-width: none;
}

.col-0 {
  display: none;
}

.col-1,
.col-1\/6 {
  display: block;
  flex-basis: calc(1 * var(--grid-column-width));
  max-width: calc(1 * var(--grid-column-width));
}

.col-2,
.col-1\/3,
[col="2"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary */ {
  display: block;
  flex-basis: calc(2 * var(--grid-column-width));
  max-width: calc(2 * var(--grid-column-width));
}

.col-3,
.col-1\/2 {
  display: block;
  flex-basis: calc(3 * var(--grid-column-width));
  max-width: calc(3 * var(--grid-column-width));
}

.col-4,
.col-2\/3 {
  display: block;
  flex-basis: calc(4 * var(--grid-column-width));
  max-width: calc(4 * var(--grid-column-width));
}

.col-5,
.col-5\/6 {
  display: block;
  flex-basis: calc(5 * var(--grid-column-width));
  max-width: calc(5 * var(--grid-column-width));
}

.col-6,
.col-full,
[col="6"] /* DEPRECATED AS OF 3.2.0 -- still used by web-profile */ {
  display: block;
  flex-basis: calc(6 * var(--grid-column-width));
  max-width: calc(6 * var(--grid-column-width));
}

@screen md {
  /**
    The grid increases to 12 columns at tablet sizes, up from 6 at mobile sizes.
    To simplify mobile-first development, we double all column widths starting at tablet sizes to maintain consistency.
    A 6-column wide block would be full-width on mobile, so we'll default to full width (12 columns) on tablet.
    (5 mobile columns become 10 tablet ones, 4 mobile columns become 8 tablet ones, etc.)
    `col-md` and `col-lg` can still be used to override mobile sizes (e.g. `col-md="6"` will only take up 6 columns)
   */
  .col-1,
  .col-1\/6 {
    display: block;
    flex-basis: calc(2 * var(--grid-column-width));
    max-width: calc(2 * var(--grid-column-width));
  }

  .col-1\/4 {
    display: block;
    flex-basis: calc(3 * var(--grid-column-width));
    max-width: calc(3 * var(--grid-column-width));
  }

  .col-2,
  .col-1\/3,
  [col="2"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary */ {
    display: block;
    flex-basis: calc(4 * var(--grid-column-width));
    max-width: calc(4 * var(--grid-column-width));
  }

  .col-3,
  .col-1\/2 {
    display: block;
    flex-basis: calc(6 * var(--grid-column-width));
    max-width: calc(6 * var(--grid-column-width));
  }

  .col-5,
  .col-5\/6 {
    display: block;
    flex-basis: calc(10 * var(--grid-column-width));
    max-width: calc(10 * var(--grid-column-width));
  }

  .col-6,
  [col="6"] /* DEPRECATED AS OF 3.2.0 -- still used by web-profile */ {
    display: block;
    flex-basis: calc(12 * var(--grid-column-width));
    max-width: calc(12 * var(--grid-column-width));
  }

  .col-7 {
    display: block;
    flex-basis: calc(7 * var(--grid-column-width));
    max-width: calc(7 * var(--grid-column-width));
  }

  .col-8,
  .col-2\/3,
  .col-4,
  [col="8"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary */ {
    display: block;
    flex-basis: calc(8 * var(--grid-column-width));
    max-width: calc(8 * var(--grid-column-width));
  }

  .col-9,
  .col-3\/4 {
    display: block;
    flex-basis: calc(9 * var(--grid-column-width));
    max-width: calc(9 * var(--grid-column-width));
  }

  .col-10 {
    display: block;
    flex-basis: calc(10 * var(--grid-column-width));
    max-width: calc(10 * var(--grid-column-width));
  }

  .col-11 {
    display: block;
    flex-basis: calc(11 * var(--grid-column-width));
    max-width: calc(11 * var(--grid-column-width));
  }

  .col-12,
  .col-full,
  [col="12"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary and web-profile */ {
    display: block;
    flex-basis: calc(12 * var(--grid-column-width));
    max-width: calc(12 * var(--grid-column-width));
  }

  .md\:col-auto,
  [col-md="auto"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary and web-profile */ {
    flex-basis: 0;
    max-width: none;
  }

  .md\:col-0 {
    display: none;
  }

  .md\:col-1 {
    display: block;
    flex-basis: calc(var(--grid-column-width));
    max-width: calc(var(--grid-column-width));
  }

  .md\:col-2,
  .md\:col-1\/6 {
    display: block;
    flex-basis: calc(2 * var(--grid-column-width));
    max-width: calc(2 * var(--grid-column-width));
  }

  .md\:col-3,
  .md\:col-1\/4,
  [col-md="3"] /* DEPRECATED AS OF 3.2.0 -- still used in web-profile */ {
    display: block;
    flex-basis: calc(3 * var(--grid-column-width));
    max-width: calc(3 * var(--grid-column-width));
  }

  .md\:col-4,
  .md\:col-1\/3 {
    display: block;
    flex-basis: calc(4 * var(--grid-column-width));
    max-width: calc(4 * var(--grid-column-width));
  }

  .md\:col-5,
  [col-md="5"] /* DEPRECATED AS OF 3.2.0 -- still used in web-profile */ {
    display: block;
    flex-basis: calc(5 * var(--grid-column-width));
    max-width: calc(5 * var(--grid-column-width));
  }

  .md\:col-6,
  .md\:col-1\/2,
  [col-md="6"] /* DEPRECATED AS OF 3.2.0 -- still used in web-profile */ {
    display: block;
    flex-basis: calc(6 * var(--grid-column-width));
    max-width: calc(6 * var(--grid-column-width));
  }

  .md\:col-7 {
    display: block;
    flex-basis: calc(7 * var(--grid-column-width));
    max-width: calc(7 * var(--grid-column-width));
  }

  .md\:col-8,
  .md\:col-2\/3,
  [col-md="8"] /* DEPRECATED AS OF 3.2.0 -- still used in web-profile */ {
    display: block;
    flex-basis: calc(8 * var(--grid-column-width));
    max-width: calc(8 * var(--grid-column-width));
  }

  .md\:col-9,
  .md\:col-3\/4,
  [col-md="9"] /* DEPRECATED AS OF 3.2.0 -- still used in web-profile */ {
    display: block;
    flex-basis: calc(9 * var(--grid-column-width));
    max-width: calc(9 * var(--grid-column-width));
  }

  .md\:col-10,
  .md\:col-5\/6 {
    display: block;
    flex-basis: calc(10 * var(--grid-column-width));
    max-width: calc(10 * var(--grid-column-width));
  }

  .md\:col-11 {
    display: block;
    flex-basis: calc(11 * var(--grid-column-width));
    max-width: calc(11 * var(--grid-column-width));
  }

  .md\:col-12,
  .md\:col-full {
    display: block;
    flex-basis: calc(12 * var(--grid-column-width));
    max-width: calc(12 * var(--grid-column-width));
  }
}

@screen lg {
  .lg\:col-auto {
    flex-basis: 0;
    max-width: none;
  }

  .lg\:col-0,
  .lg\:col-hidden {
    display: none;
  }

  .lg\:col-1 {
    display: block;
    flex-basis: calc(var(--grid-column-width));
    max-width: calc(var(--grid-column-width));
  }

  .lg\:col-2,
  .lg\:col-1\/6 {
    display: block;
    flex-basis: calc(2 * var(--grid-column-width));
    max-width: calc(2 * var(--grid-column-width));
  }

  .lg\:col-3,
  .lg\:col-1\/4 {
    display: block;
    flex-basis: calc(3 * var(--grid-column-width));
    max-width: calc(3 * var(--grid-column-width));
  }

  .lg\:col-4,
  .lg\:col-1\/3,
  [col-lg="4"] /* DEPRECATED AS OF 3.2.0 -- still used in web-dispensary */ {
    display: block;
    flex-basis: calc(4 * var(--grid-column-width));
    max-width: calc(4 * var(--grid-column-width));
  }

  .lg\:col-5 {
    display: block;
    flex-basis: calc(5 * var(--grid-column-width));
    max-width: calc(5 * var(--grid-column-width));
  }

  .lg\:col-6,
  .lg\:col-1\/2,
  [col-lg="one-half"] /* DEPRECATED AS OF 3.2.0 -- still used in web-strain-explorer */ {
    display: block;
    flex-basis: calc(6 * var(--grid-column-width));
    max-width: calc(6 * var(--grid-column-width));
  }

  .lg\:col-7 {
    display: block;
    flex-basis: calc(7 * var(--grid-column-width));
    max-width: calc(7 * var(--grid-column-width));
  }

  .lg\:col-8,
  .lg\:col-2\/3 {
    display: block;
    flex-basis: calc(8 * var(--grid-column-width));
    max-width: calc(8 * var(--grid-column-width));
  }

  .lg\:col-9,
  .lg\:col-3\/4 {
    display: block;
    flex-basis: calc(9 * var(--grid-column-width));
    max-width: calc(9 * var(--grid-column-width));
  }

  .lg\:col-10,
  .lg\:col-5\/6 {
    display: block;
    flex-basis: calc(10 * var(--grid-column-width));
    max-width: calc(10 * var(--grid-column-width));
  }

  .lg\:col-11 {
    display: block;
    flex-basis: calc(11 * var(--grid-column-width));
    max-width: calc(11 * var(--grid-column-width));
  }

  .lg\:col-12 {
    display: block;
    flex-basis: calc(12 * var(--grid-column-width));
    max-width: calc(12 * var(--grid-column-width));
  }
}