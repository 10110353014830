/**
Align items

These utilities make custom row alignment simple.
They apply at all screen sizes by default, and can be optionally enabled for tablet and desktop sizes. <br />
To apply styles starting at tablet sizes, prefix with <strong>sm:</strong>, e.g. <strong>sm:items-start</strong>. <br />
To apply styles starting at desktop sizes, prefix with <strong>lg:</strong>, e.g. <strong>lg:items-start</strong>.

.items-start     - Applies `align-items: flex-start`
.items-end       - Applies `align-items: flex-end`
.items-center    - Applies `align-items: center`
.items-stretch   - Applies `align-items: stretch`

Markup:
<div row class="{{modifier_class}} bg-deep-green-40">
  <div col="2">Mr. Windibank sprang out of his chair and picked up his hat.</div>
  <div col="2">"I cannot waste time over this sort of fantastic talk, Mr. Holmes," he said.</div>
</div>

Weight: 2.5

Style guide: Layout - Utilities - Align items
 */

/**
Justify items

These utilities make custom flex item justification simple.
They apply at all screen sizes by default, and can be optionally enabled for tablet and desktop sizes. <br />
To apply styles starting at tablet sizes, prefix with <strong>sm:</strong>, e.g. <strong>sm:justify-start</strong>. <br />
To apply styles starting at desktop sizes, prefix with <strong>lg:</strong>, e.g. <strong>lg:justify-start</strong>.

.justify-start   - Applies `justify-content: flex-start`
.justify-end     - Applies `justify-content: flex-end`
.justify-center  - Applies `justify-content: center`
.justify-around  - Applies `justify-content: space-around`
.justify-between - Applies `justify-content: space-between`

Markup:
<div row class="{{modifier_class}}">
  <div col="2" class="bg-deep-green-40">Mr. Windibank sprang out of his chair and picked up his hat.</div>
  <div col="2" class="bg-deep-green-40">"I cannot waste time over this sort of fantastic talk, Mr. Holmes," he said.</div>
</div>

Weight: 2.7

Style guide: Layout - Utilities - Justify items
 */

/**
[DEPRECATED] Alignment utilities

Use Tailwind-based alignment utilities (see previous sections) instead.

row-justify="start"   - Applies `justify-content: flex-start`
row-justify="end"     - Applies `justify-content: flex-end`
row-justify="center"  - Applies `justify-content: center`
row-justify="around"  - Applies `justify-content: space-around`
row-justify="between" - Applies `justify-content: space-between`
row-align="start"     - Applies `align-items: flex-start`
row-align="end"       - Applies `align-items: flex-end`
row-align="center"    - Applies `align-items: center`
row-align="stretch"   - Applies `align-items: stretch`

Markup:
<div row {{{modifier_class}}}>
  Mr. Windibank sprang out of his chair and picked up his hat.
  "I cannot waste time over this sort of fantastic talk, Mr. Holmes," he said.
</div>

Weight: 2.6

Style guide: DEPRECATED - [DEPRECATED] Alignment
 */

/* DEPRECATED */
[row-justify="start"] {
  justify-content: flex-start;
}

/* DEPRECATED */
[row-justify="end"] {
  justify-content: flex-end;
}

/* DEPRECATED */
[row-justify="center"] {
  justify-content: center;
}

/* DEPRECATED */
[row-justify="around"] {
  justify-content: space-around;
}

/* DEPRECATED */
[row-justify="between"] {
  justify-content: space-between;
}

/* DEPRECATED */
[row-align="start"] {
  align-items: flex-start;
}

/* DEPRECATED */
[row-align="end"] {
  align-items: flex-end;
}

/* DEPRECATED */
[row-align="center"] {
  align-items: center;
}

/* DEPRECATED */
[row-align="stretch"] {
  align-items: stretch;
}

@screen md {
  /* DEPRECATED */
  [row-justify-md="start"] {
    justify-content: flex-start;
  }

  /* DEPRECATED */
  [row-justify-md="end"] {
    justify-content: flex-end;
  }

  /* DEPRECATED */
  [row-justify-md="center"] {
    justify-content: center;
  }

  /* DEPRECATED */
  [row-justify-md="around"] {
    justify-content: space-around;
  }

  /* DEPRECATED */
  [row-justify-md="between"] {
    justify-content: space-between;
  }

  /* DEPRECATED */
  [row-align-md="start"] {
    align-items: flex-start;
  }

  /* DEPRECATED */
  [row-align-md="end"] {
    align-items: flex-end;
  }

  /* DEPRECATED */
  [row-align-md="center"] {
    align-items: center;
  }

  /* DEPRECATED */
  [row-align-md="stretch"] {
    align-items: stretch;
  }
}

@screen lg {
  [row-justify-lg="start"] {
    justify-content: flex-start;
  }

  [row-justify-lg="end"] {
    justify-content: flex-end;
  }

  [row-justify-lg="center"] {
    justify-content: center;
  }

  [row-justify-lg="around"] {
    justify-content: space-around;
  }

  [row-justify-lg="between"] {
    justify-content: space-between;
  }

  [row-align-lg="start"] {
    align-items: flex-start;
  }

  [row-align-lg="end"] {
    align-items: flex-end;
  }

  [row-align-lg="center"] {
    align-items: center;
  }

  [row-align-lg="stretch"] {
    align-items: stretch;
  }
}
