/**
Selects

Markup:
<div class="bg-white p-xl">
  Unlabeled, no helper text:
  <div class="form__field">
    <select class="unlabeled">
      <option value="" selected="selected">Selection</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
  </div>
  Unlabeled, helper text:
  <div class="form__field">
    <select>
      <option value="" selected="selected">Selection</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
    <p>Helper text (optional)</p>
  </div>
  Labeled, helper text:
  <div class="form__field">
    <label for="sample-select">Label (optional)</label>
    <select id="sample-select">
      <option value="" selected="selected">Selection</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
    <p>Helper text (optional)</p>
  </div>
  Unlabeled, helper text:
  <div class="form__field">
    <select>
      <option value="" selected="selected">Selection</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
    <p>Helper text (optional)</p>
  </div>
  <div class="form__field">
    <select disabled=true>
      <option value="" selected="selected">Disabled</option>
      <option value="1">Option #1</option>
      <option value="2">Option #2</option>
      <option value="3">Option #3</option>
    </select>
  </div>
</div>

Weight: 3.2

Style guide: Forms - Selects
 */
select {
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgwVjB6Ii8+PHBhdGggc3Ryb2tlPSIjMzMzIiBmaWxsPSIjMzMzIiBkPSJtNyAxMCA1IDUgNS01SDd6Ii8+PC9zdmc+");
  background-position: calc(100% - 8px) 10px;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 8px 12px;
}

label ~ select {
  padding: 24px 8px 8px;
}

select:focus-visible {
  outline: none;
  outline: 2px solid var(--color-default);
  outline-offset: -2px;
}

.form__field > label ~ select {
  background-position: calc(100% - 8px) center;

  @apply py-lg;
  @apply px-sm;
  @apply text-md;
  @apply text-default;
}

option {
  padding-left: 0;
}

/**
Dates

Use the <strong>form__field</strong> and <strong>form__field--date</strong> classes to wrap date selection dropdowns.

Markup:
<div class="bg-white p-xl">
  <div class="form__field form__field--date">
    <select name="month">
      <option value="">Month</option>
      <option value="01">January</option>
      <option value="02">February</option>
      <option value="03">March</option>
      <option value="04">April</option>
      <option value="05">May</option>
      <option value="06">June</option>
      <option value="07">July</option>
      <option value="08">August</option>
      <option value="09">September</option>
      <option value="10">October</option>
      <option value="11">November</option>
      <option value="12">December</option>
    </select>
    <select name="day">
      <option value="">Day</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
    </select>
    <select name="year">
      <option value="">Year</option>
      <option value="1900">1900</option>
      <option value="1901">1901</option>
      <option value="1902">1902</option>
    </select>
  </div>
</div>

Weight: 3.3

Style guide: Forms - Dates
 */
.form__field--date {
  display: flex;

  @apply text-green;
  @apply text-sm;
}

.form__field--date > select:not(:last-child) {
  margin-right: 8px;
}
