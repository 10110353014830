/**
Radio buttons

Markup: radio.html

Weight: 3.5

Background: bg-white

Style guide: Forms - Radio buttons
 */
.radio__label,
.form__field > .radio__label {
  cursor: pointer;
  display: flex;
  position: relative;

  @apply text-sm;
}

.radio__label > span {
  @apply ml-sm;
}

.radio__label--before {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.radio__label--before > span {
  @apply mx-sm;
  @apply order-first;
}

.radio__container {
  @apply bg-white;

  border: 1.75px solid;
  height: 20px;
  margin-top: 2px;
  overflow: hidden;
  width: 20px;

  @apply rounded-full;
  @apply flex;
  @apply flex-shrink-0;
}

.radio__container > input {
  transition: all 0.25s ease 0s;
  appearance: none;

  @apply cursor-pointer;
  @apply border-transparent;
  @apply bg-white;
}

.radio__container > input:checked + .radio__fill {
  background-color: var(--color-default);
  height: 12px;
  left: 4px;
  position: absolute;
  top: 6px;
  width: 12px;

  @apply rounded-full;
}

.radio__label--before .radio__container > input:checked + .radio__fill {
  left: auto;
  right: 4px;
}
