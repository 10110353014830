/**
Strain Tile

Markup:
Default:
<a class="strain-tile">
  <img class="strain-tile__image" src="https://public.leafly.com/flower-generator/animal-cookies-nucleus.svg" />
  <div class="strain-tile__name">
    Animal Cookies
  </div>
  <span class="tag tag--outline">Most invigorating</span>
</a>
Card-style:
<a class="card strain-tile">
  <img class="strain-tile__image" src="https://public.leafly.com/flower-generator/blue-dream-nucleus.svg" />
  <div class="strain-tile__name">
    Blue Dream
  </div>
  <span class="tag tag--outline">Most relaxing</span>
</a>
Grid-style:
<div class="row">
  <div class="col-1/2">
    <a class="strain-tile">
      <img class="strain-tile__image" src="https://public.leafly.com/flower-generator/orange-cookies-nucleus.svg" />
      <div class="strain-tile__name">
        Orange Cookies
      </div>
      <span class="tag tag--outline">Grandma's favorite</span>
    </a>
  </div>
  <div class="col-1/2">
    <a class="strain-tile">
      <img class="strain-tile__image" src="https://public.leafly.com/flower-generator/confidential-cheese-nucleus.svg" />
      <div class="strain-tile__name">
        Confidential Cheese
      </div>
      <span class="tag tag--outline">Top secret</span>
    </a>
  </div>
</div>

Weight: 5

Style guide: Patterns - Strain Tile
 */
.strain-tile {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  place-items: center;
  justify-content: center;

  @apply bg-white;
  @apply px-lg;
  @apply py-md;
}

.strain-tile__image {
  height: 120px;
  width: 120px;

  @apply mb-sm;
}

.strain-tile__name {
  @apply font-bold;
  @apply mb-sm;
  @apply text-md;

  text-align: center;
}
