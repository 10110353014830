/**
Section Headers

Headers for individual page sections. (For spacing between sections use `mb-section`, see Margins)

Markup:
<!-- Section header -->
<a href="#">
  <div class="section__header">
    <div class="align-bottom flex justify-start">
      <h2 class="heading--l">Section Header default (h2)</h2>
    </div>
    <div class="text-green text-xs">Action</div>
  </div>
</a>
<a href="#">
  <div class="section__header">
    <div class="align-bottom flex justify-start">
      <h3 class="heading--m">Section Header h3</h3>
    </div>
    <div class="text-green text-xs">Action</div>
  </div>
</a>
<a href="#">
  <div class="section__header">
    <div class="align-bottom flex justify-start">
      <h4 class="heading--s">Section Header h4</h4>
    </div>
    <div class="text-green text-xs">Action</div>
  </div>
</a>
<a href="#">
  <div class="section__header">
    <div class="align-bottom flex justify-start">
      <h2 class="heading--l">Section Header Default w/ tidbit</h2>
      <span class="ml-sm block self-end text-green text-sm" style="margin-bottom: 2px;">tidbit</span>
    </div>
    <div class="text-green text-xs">Action</div>
  </div>
</a>


Weight: 11

Style guide: Layout - Section Headers
 */
.section__header {
  @apply flex;
  @apply font-bold;
  @apply items-end;
  @apply justify-between;
  @apply mb-sm;
  @apply relative;
}
