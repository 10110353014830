/**
Generic Carousel Card

Carousel cards are a slightly specialized form of card with a fixed width and height designed to fit our carousels.
By default, 3 cards fit in per carousel carriage at desktop sizes.

Markup:
Default:
<div class="carousel-card">
  <span class="card__ad-flag">Ad</span>
  <div class="card__image">
    <img src="https://placekitten.com/g/350/350" alt="carousel name" />
  </div>
  <div class="carousel-card__content">
    <div>
      <span class="tag">Tag</span>
      <span class="tag">Tag</span>
      <div class="carousel-card__title">Primary text</div>
    </div>
    <div class="text-sm">Secondary text</div>
  </div>
</div>
See-more:
<div class="row">
  <div class="col-1/3">
    <a class="carousel-card--see-more" href="#">
      <span class="carousel-card__circle--see-more">
        <svg width="20" height="20">
          <use xlink:href="#arrow_forward" href="#arrow_forward" />
        </svg>
      </span>
      <span class="carousel-card__label-narrow--see-more">See more</span>
    </a>
  </div>
  <div class="col-1/3">
    <a class="carousel-card--see-more" href="#">
      <span class="carousel-card__circle--see-more">
        <svg width="20" height="20">
          <use xlink:href="#arrow_forward" href="#arrow_forward" />
        </svg>
      </span>
      <span>See more longer text</span>
    </a>
  </div>
</div>

Weight: 4

Style guide: Patterns - Generic Carousel Card
 */
.carousel-card {
  display: block;
  position: relative;
  text-align: left;
  width: 282px;

  @apply bg-white;
  @apply mr-md;
  @apply mr-md;
}

@screen md {
  .carousel-card {
    width: 300px;
  }
}

@screen lg {
  .carousel-card {
    width: 322.67px;
  }
}

.carousel-card__content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 160px);
  justify-content: space-between;
  max-height: calc(100% - 160px);
  overflow: hidden;

  @apply p-md;
}

.carousel-card__title {
  @apply font-bold;
  @apply text-md;
}

.carousel-card--see-more {
  width: 100%;
  height: 100%;

  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-center;
  @apply text-center;
  @apply text-sm;
}

.carousel-card__circle--see-more {
  height: 60px;
  width: 60px;

  @apply bg-deep-green-20;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply mb-md;
  @apply rounded-full;
}

.carousel-card__label-narrow--see-more {
  width: 60px;
}

/**
Carousel Card Quadruplets

To be used in tandem with .carousel-card when you want exactly 4 cards (not 3) per carriage in a desktop-sized carousel.

Markup:
<div class="carousel__mask">
  <div class="carousel__items">
    <div class="carousel-card carousel-card--quadruplet">
      Wow
    </div>
    <div class="carousel-card carousel-card--quadruplet">
      Wow
    </div>
    <div class="carousel-card carousel-card--quadruplet">
      Wow
    </div>
    <div class="carousel-card carousel-card--quadruplet">
      Wow
    </div>
  </div>
</div>

Weight: 6

Style guide: Patterns - 3-4 Item Carousel Card
*/
.carousel-card--quadruplet {
  @apply mr-md;

  width: 225px;
}

@screen md {
  .carousel-card--quadruplet {
    width: 300px;
  }
}

@screen lg {
  .carousel-card--quadruplet {
    width: 239px;
  }
}

/**
Image Card Styles

An Image Card's base style has no predetermined width and should only appear within a carousel; widths can be determined via the carousel-card--(triplet|quadruplet) styles.
Add a transparent -> black gradient to your image, or any other element via .carousel-card--gradient.

Markup:
Without gradient:
<a class="carousel-card carousel-card--quadruplet" href="https://leafly.com/news/cool-new-article">
  <img class="image-card__image" src="https://via.placeholder.com/224x160?text=224x160%20Cover%20photo" alt="cool new article" />
  <div class="image-card__title" >Check out this cool article</div>
</a>
With gradient:
<a class="carousel-card carousel-card--quadruplet carousel-card--gradient" href="https://leafly.com/news/cool-new-article">
  <img class="image-card__image" src="https://via.placeholder.com/224x160?text=224x160%20Cover%20photo" alt="cool new article" />
  <div class="image-card__title" >Check out this cool article</div>
</a>

Weight: 7

Style guide: Patterns - Image Carousel Card
 */
.image-card__image {
  height: 225px;

  @apply w-full;
  @apply object-cover;
}

.image-card__title {
  @apply absolute;
  @apply bottom-0;
  @apply left-0;
  @apply m-lg;
  @apply z-10;
}

.carousel-card--gradient {
  @apply text-white;
}

.carousel-card--gradient::before {
  background-image: linear-gradient(to bottom, transparent 0%, black 100%);
  content: "";

  @apply absolute;
  @apply inset-0;
  @apply z-10;
}

/**
Article Card

Article cards display information about a single news article and may appear in carousels or grids.

.carousel-card  - Use a `carousel-card` wrapper when the card is part of a carousel.
.card           - Use a `card` wrapper when the card is part of a grid.

Markup:
<div class="{{modifier_class}} article-card">
  <div class="card__image">
    <img src="https://via.placeholder.com/224x160?text=Cover%20photo" alt="carousel name" />
  </div>
  <div class="carousel-card__content">
    <div>
      <span class="tag">Tag</span>
      <span class="tag">Tag</span>
      <div class="carousel-card__title">Article title</div>
    </div>
    <div>
      <div class="article-card__author">Author name</div>
      <div class="article-card__date">April 1, 2020</div>
    </div>
  </div>
</div>

Weight: 9

Style guide: Patterns - Article Card
 */
.article-card {
  height: 360px;
}

.article-card__author {
  @apply text-sm;
}

.article-card__date {
  @apply text-xs;
}
