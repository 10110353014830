/**
Review card
Markup:
<article class="review-card">
  <div class="card__header">
    <img src="https://via.placeholder.com/150" alt="Logo" class="card__thumbnail" />
    <div class="card__title">
      <h4 class="heading--m">Reviewer name</h4>
      <div class="text-xs">Member since 2015</div>
    </div>
  </div>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec gravida ipsum.
    Quisque mauris sapien, dignissim id lobortis id, mollis vel sapien. Suspendisse sit
    amet sem convallis, elementum velit eget, accumsan lorem. Maecenas egestas metus ac
    justo cursus lobortis. Proin ultrices facilisis viverra.
  </p>
  <a href="#" class="button--text">Read more</a>
</article>
Weight: 12
Style guide: Patterns - Review card
*/
.review-card {
    @apply bg-white;
    @apply p-lg;
    @apply text-sm;
  }
  
  .review-card > p {
    @apply text-sm;
  }
  
  /**
  Dispensary review card
  Markup:
  <article class="review-card">
    <div class="card__header">
      <img src="https://via.placeholder.com/150" alt="Logo" class="card__thumbnail" />
      <div class="card__title">
        <h4 class="heading--m">Reviewer name</h4>
        <div class="text-xs">on March 1, 2018</div>
      </div>
    </div>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec gravida ipsum.
      Quisque mauris sapien, dignissim id lobortis id, mollis vel sapien. Suspendisse sit
      amet sem convallis, elementum velit eget, accumsan lorem. Maecenas egestas metus ac
      justo cursus lobortis. Proin ultrices facilisis viverra.
    </p>
    <footer class="dispensary-review-card__footer">
      <button class="button--text icon--centered">
        Helpful (2)
        <svg width="12" height="12" class="ml-xs">
          <use xlinkHref="#thumb_up" href="#thumb_up" />
        </svg>
      <button class="button--text">Report</button>
    </footer>
  </article>
  Weight: 14
  Style guide: Patterns - Dispensary review card
  */
  
  /* TODO: Needs "Show more" styles for long reviews */
  .dispensary-review-card__footer {
    display: flex;
    place-content: center space-between;

    @apply font-bold;
    @apply text-xs;
  }
